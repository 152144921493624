import React, { useContext, useEffect, useState } from "react"
import tw from "twin.macro"
import { SeriesSectionProps } from "./Lifestyle.d"
import { Image } from "../../atoms/Image"
import { Gallery } from "../Series"
import { LocalInventoryLinks } from "../../molecules/LocalInventoryLinks"
import { LocationContext } from "../../../contexts/Location"
import { InventoryClient } from "../../../clients/InventoryClient"
import { ButtonLink } from "../../atoms/Button"
import {
  generateInventoryLink,
  getCTALinkValue,
  parseDisclaimerBlocks,
} from "../../../helpers"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { useTealiumContext } from "../../../contexts/Tealium"
import { LanguageContext } from "../../../contexts/Language"
import { SimpleGallery } from "../../molecules/SimpleGallery"
import { toggleDisclaimersModal } from "../../../contexts/Disclaimers/actions"
import { DisclaimersContext } from "../../../contexts/Disclaimers"

const SeriesSection: React.FC<SeriesSectionProps> = ({
  headingText,
  description,
  logo,
  lifestyleSeries,
  backgroundImage,
  selectedSeries,
  setSelectedSeries,
  seriesSectionRef,
}) => {
  const [{ city, dealers }] = useContext(LocationContext)
  const { _ } = useContext(LanguageContext)
  const [state, dispatch] = useContext(DisclaimersContext)
  const [inventoryCount, setInventoryCount] = useState<number | "...">(0)
  const [selectedSeriesIndex, setSelectedSeriesIndex] = useState(0)
  useEffect(() => {
    const getCount = async () => {
      const dealerIds = dealers?.map(dealer => dealer?.DealerCode).join(",")
      try {
        setInventoryCount("...")
        const { count } = await InventoryClient.getCount("", {
          dealer: dealerIds,
          series: selectedSeries.seriesName,
        })
        setInventoryCount(count)
      } catch (error) {
        setInventoryCount(error)
      }
    }
    getCount()
  }, [dealers, selectedSeries])

  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  const { vehicleTealData } = useTealiumContext()

  const handleTealiumEvent = (selection: string) => {
    trackTealEvent({
      tealium_event: "carousel_click",
      carousel_action: selection,
    })
  }
  return (
    <section aria-label="Series Section" css={[tw`bg-gray-900`]}>
      {/* BACKGROUND IMAGE */}
      <div
        css={[
          tw`relative flex h-[420px] overflow-hidden`,
          tw`lg:(relative flex h-[450px])`,
        ]}
      >
        {backgroundImage && (
          <Image
            imageData={backgroundImage?.image}
            css={[
              tw`absolute top-0 bottom-0 w-full contrast-150`,
              "z-index: 0;",
            ]}
          />
        )}
        <div
          css={[
            tw`w-full mx-auto p-6 z-50 h-auto`,
            tw`lg:(flex w-full mx-auto h-auto -mt-40 items-center max-w-7xl px-20)`,
          ]}
        >
          <h1
            css={[
              tw`text-4xl font-light tracking-widest w-full`,
              tw`xl:(w-[380px])`,
            ]}
          >
            {parseDisclaimerBlocks(headingText, selection =>
              dispatch(toggleDisclaimersModal(selection))
            )}
          </h1>
          <div css={[tw`w-auto flex-grow`]}>
            <p css={[tw`py-6`, tw`xl:(ml-10)`]}>
              {parseDisclaimerBlocks(description, selection =>
                dispatch(toggleDisclaimersModal(selection))
              )}
            </p>
          </div>
          <div css={[tw`hidden`, tw`lg:(w-auto flex flex-grow)`]}>
            se
            <Image imageData={logo.image} />
          </div>
        </div>
        {/* BACKGROUND GRADIENT OVERLAY */}
        <div
          css={[
            tw`absolute h-[450px] brightness-150 top-0 right-0 bottom-0 left-0`,
            tw`lg:(absolute h-[450px] brightness-150 top-0 right-0 bottom-0 left-0)`,
            `z-index: 1;
          background: 
            linear-gradient(
              to bottom, 
              rgba(255, 255, 255, .9) 54%, 
              rgba(255, 255, 255, 0.4) 64%, 
              rgba(255, 255, 255, 0.1) 100%)`,
            `background-size: 100% auto`,
          ]}
        ></div>
      </div>
      <div
        css={[
          tw`relative -mt-16 flex flex-shrink items-start justify-between -mr-20 w-auto mx-auto px-20 pr-0 z-20 flex-nowrap overflow-x-scroll`,
          tw`xl:(relative -mt-28 flex items-center justify-center max-w-7xl mx-auto px-20 z-20)`,
        ]}
      >
        {lifestyleSeries.map((series, index) => (
          <div
            ref={index === selectedSeriesIndex ? seriesSectionRef : null} // Use seriesSectionRef only for the selected series
            key={index}
            id={`category-${index + 1}`}
            css={[
              tw`w-[300px] min-w-[300px] relative cursor-pointer -ml-20 mb-6`,
              tw`xl:(-ml-20) w-[320px] min-w-[320px]`,
            ]}
            analytics-id={`vehicle tile:selection:${index + 1}`}
            onClick={() => {
              setSelectedSeries(series)
              setSelectedSeriesIndex(index)
              handleTealiumEvent(series.seriesName)
            }}
          >
            <Image
              imageData={series.seriesImage.image}
              css={[tw`h-[165px]`]}
              objectFit={"contain"}
            />
            <div
              css={[
                tw`pb-12 pt-10 mx-8 text-center items-center justify-center -mt-10`,
                selectedSeries &&
                  selectedSeries.seriesCategory === series.seriesCategory &&
                  tw`shadow-4`,
              ]}
            >
              <h3
                css={[
                  tw`text-gray-100 inline-flex font-light uppercase tracking-widest text-center text-sm border-b-2 border-b-transparent px-2 py-1`,
                  selectedSeries &&
                    selectedSeries.seriesCategory === series.seriesCategory &&
                    tw`border-b-red-400`,
                ]}
              >
                {series.seriesName}
              </h3>
              <h4
                css={[
                  tw`text-gray-100 font-semibold uppercase tracking-widest text-center text-base pt-2`,
                ]}
              >
                {series.seriesCategory}
              </h4>
            </div>
          </div>
        ))}
      </div>
      {selectedSeries && (
        <div css={[tw`bg-transparent`, tw`lg:(relative bg-gray-100)`]}>
          {selectedSeries.seriesBackgroundImage && (
            <div
              css={[
                tw`hidden`,
                tw`lg:(block absolute h-[450px] top-0 right-0 bottom-0 left-0 overflow-hidden bg-white)`,
              ]}
            >
              <Image
                imageData={selectedSeries.seriesBackgroundImage.image}
                objectFit={"cover"}
                css={[
                  tw`absolute -top-60 bottom-0 right-0 left-0 w-full`,
                  "z-index: 1;",
                ]}
              />
            </div>
          )}
          <div
            css={[
              tw`hidden`,
              tw`lg:(block absolute h-[450px] brightness-100 top-0 right-0 bottom-0 left-0)`,
              `z-index: 1;
          background: 
            linear-gradient(
              to right, 
              rgba(255, 255, 255, 1) 10%,
              rgba(255, 255, 255, .9) 30%, 
              rgba(255, 255, 255, 0.4) 45%, 
              rgba(255, 255, 255, 0.1) 100%)`,
              `background-size: 100% auto`,
            ]}
          ></div>
          <div
            css={[
              tw`hidden`,
              tw`lg:(block absolute h-[450px] top-0 right-0 bottom-0 left-0)`,
              `z-index: 1;
          background: 
            linear-gradient(
              to bottom, 
              rgba(255, 255, 255, 1) 3%,
              rgba(255, 255, 255, .5) 13%,  
              rgba(255, 255, 255, .0) 26%)`,
              `background-size: 100% auto`,
            ]}
          ></div>
          <div
            css={[
              tw`relative overflow-hidden px-6 z-10`,
              tw`lg:(relative overflow-hidden flex items-center space-x-9 pl-28 z-10 pb-20)`,
            ]}
          >
            <div
              css={[
                tw`w-full text-white`,
                tw`lg:(w-[450px] h-[600px]text-black )`,
              ]}
            >
              <h2
                css={[
                  tw`text-4xl font-semibold tracking-widest uppercase mt-10 text-white`,
                  tw`lg:(text-5xl text-black)`,
                ]}
              >
                {selectedSeries.seriesName}
              </h2>
              <p css={[tw`py-8`]}>
                {parseDisclaimerBlocks(selectedSeries.description, selection =>
                  dispatch(toggleDisclaimersModal(selection))
                )}
              </p>
              <span css={[tw`font-semibold`]}>{_("Perfect for")}:</span>
              <div css={[tw`grid grid-cols-2`]}>
                <div css={[tw`col-span-1 flex items-center`]}>
                  <ul>
                    {selectedSeries.perfectFor.map(
                      (item: any, index: number) => (
                        <li css={[tw`font-semibold`]} key={index}>
                          {item}
                        </li>
                      )
                    )}
                  </ul>
                </div>
                <div
                  css={[tw`col-span-1`]}
                  analytics-id="explore series:vehicle info:"
                >
                  {selectedSeries.cta && (
                    <ButtonLink
                      to={getCTALinkValue(selectedSeries?.cta)}
                      target={
                        selectedSeries?.cta?.linkType === "external"
                          ? "_blank"
                          : "_self"
                      }
                      {...{ [selectedSeries.cta.buttonType]: true }}
                      css={[tw`max-w-max`]}
                      onClick={() => {
                        trackTealEvent({
                          tealium_event: "cta_click",
                          ...(vehicleTealData?.vehicle_model && {
                            vehicle_model: vehicleTealData?.vehicle_model,
                          }),
                          ...(vehicleTealData?.vehicle_model_truncated && {
                            vehicle_model_truncated:
                              vehicleTealData?.vehicle_model_truncated,
                          }),
                          link_href: `${getCTALinkValue(selectedSeries?.cta)}`,
                          coupon_module_text: `${selectedSeries?.cta?.title}`,
                        })
                      }}
                    >
                      {selectedSeries.cta.title}
                    </ButtonLink>
                  )}
                </div>
              </div>
              <div css={[tw`mt-32`]}>
                <LocalInventoryLinks
                  city={city}
                  dealerCount={dealers?.length}
                  inventoryCount={inventoryCount}
                  series={selectedSeries.slug}
                  inventoryLink={generateInventoryLink({
                    series: selectedSeries.seriesName.toLowerCase(),
                  })}
                  analyticsId="vehicle info:"
                />
              </div>
            </div>
            <div css={[tw`-right-32 absolute bottom-0`]}>
              <Image
                imageData={selectedSeries.seriesImage.image}
                css={[
                  tw`hidden`,
                  tw`sm:(block w-[400px] h-auto)`,
                  tw`md:(w-[500px])`,
                  tw`lg:(w-[700px])`,
                  tw`xl:(w-[800px])`,
                ]}
              />
            </div>
          </div>
          <h1
            css={tw`block text-center text-4xl font-light tracking-widest py-10`}
          >
            {parseDisclaimerBlocks(selectedSeries.gallery.title, selection =>
              dispatch(toggleDisclaimersModal(selection))
            )}
          </h1>

          <div css={[tw`max-w-7xl mx-auto`]}>
            <SimpleGallery
              {...selectedSeries.gallery}
              selectedSeries={selectedSeries.seriesName}
              selectedSeriesYear={selectedSeries.vehicle.year}
              hasExtraTealData={true}
            />
          </div>
        </div>
      )}
    </section>
  )
}

export default SeriesSection
