import React, { useContext, useRef } from "react"
import { bgImageWithGeoLocation, LifestyleHeroProps } from "./Lifestyle.d"
import tw from "twin.macro"
import { ButtonLink } from "../../atoms/Button"
import { Image } from "../../atoms/Image"
import { CTA } from "../../../global"
import { Select, Option } from "../../atoms/Select"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { useTealiumContext } from "../../../contexts/Tealium"
import { LanguageContext } from "../../../contexts/Language"
import { getCTALinkValue, parseDisclaimerBlocks } from "../../../helpers"
import { toggleDisclaimersModal } from "../../../contexts/Disclaimers/actions"
import { DisclaimersContext } from "../../../contexts/Disclaimers"

/**
 *
 * @author Cody
 * @summary
 *
 *
 */

const LifestyleHero: React.FC<LifestyleHeroProps> = ({
  backgroundImages,
  heroImage,
  cta,
  heading,
  subHeading,
  showGradient = true,
  textInverted = false,
  itemsTop = false,
  categories,
  onChange,
  seriesSectionRef,
}) => {
  const { _ } = useContext(LanguageContext)
  const [state, dispatch] = useContext(DisclaimersContext)
  // Tealium
  const { trackTealEvent } = useTealiumEvent()

  const handleTealiumEvent = (selection: string) => {
    trackTealEvent({
      tealium_event: "carousel_click",
      carousel_action: selection,
    })
  }

  /* User geolocation placeholder - Until we know how we're going to actually implement it */
  const userLocation = "US"
  /* Pull in background image that matches user geo location, if none match use 'all' */
  const bgImage: bgImageWithGeoLocation = backgroundImages?.find(
    location =>
      location?.geolocation === userLocation || location?.geolocation === "all"
  )

  const [selectedCategory, setSelectedCategory] = React.useState(categories[0])
  const handleChange = (category: any) => {
    setSelectedCategory(category[0])
    onChange && onChange(category[0])

    // Assuming you have a unique identifier for each category, such as an index
    const index = categories.indexOf(category[0])
    const element = document.getElementById(`category-${index + 1}`)

    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
      })
    }

    handleTealiumEvent(category[0])
  }

  return (
    <section
      aria-label="Lifestyle Hero Section"
      css={[
        tw`flex h-[480px] relative`,
        tw`md:(h-[580px])`,
        tw`xl:(items-center h-[680px])`,
        tw`2xl:(h-[900px])`,
        itemsTop && tw`items-start md:(items-start)`,
      ]}
    >
      {/* BACKGROUND IMAGE */}
      {bgImage && (
        <Image
          imageData={bgImage?.image?.image}
          alt={bgImage.alt}
          css={[
            tw`absolute h-auto bottom-0 top-0 w-full`,
            tw`md:(h-auto w-full object-cover)`,
            "z-index: 0;",
          ]}
        />
      )}
      {showGradient && (
        <div
          css={[
            tw`absolute w-full h-full brightness-100 hidden`,
            `z-index: 1;
          background: 
            linear-gradient(
              to bottom, 
              rgba(255, 255, 255, .9) 35%, 
              rgba(255, 255, 255, 0.1) 50%, 
              rgba(255, 255, 255, 0.0) 100%)`,
            `background-size: 100% auto`,
            tw`lg:(background[none])`,
          ]}
        ></div>
      )}
      {/* HERO SECTION */}
      <section
        css={[
          tw`grid grid-cols-1 px-6 pt-0 pb-28 max-w-7xl mx-auto z-[51] gap-4`,
          tw`lg:(pb-0 pt-10 grid-cols-2 gap-20)`,
        ]}
      >
        <div
          css={[
            tw`col-span-2 w-full mt-44`,
            tw`sm:(mt-0)`,
            tw`lg:(col-span-1 w-96 mt-0)`,
          ]}
        >
          <h1
            css={[
              tw`text-white text-3xl mt-6 mb-2`,
              tw`w-full`,
              tw`sm:(w-3/4 mb-40)`,
              tw`lg:(mb-0 col-span-1 text-4xl tracking-widest font-light w-full)`,
              textInverted && tw`text-black`,
            ]}
          >
            {heading &&
              parseDisclaimerBlocks(heading, selection =>
                dispatch(toggleDisclaimersModal(selection))
              )}
          </h1>
          <h3
            css={[
              tw`text-white text-lg`,
              tw`lg:(col-span-1 text-lg)`,
              textInverted && tw`text-black`,
            ]}
          >
            {subHeading &&
              parseDisclaimerBlocks(subHeading, selection =>
                dispatch(toggleDisclaimersModal(selection))
              )}
          </h3>
        </div>
        <div
          css={[
            tw`col-span-2 w-full bg-white shadow-4 rounded-lg mt-4 mb-2 `,
            tw`md:(mt-24)`,
            tw`lg:(col-span-1 w-full bg-white shadow-4 rounded-lg mt-4 mb-2)`,
            tw`xl:(w-[600px])`,
          ]}
        >
          <div css={[tw`p-8`]}>
            <span
              css={[
                tw`block font-light tracking-widest text-base`,
                tw`lg:(text-lg)`,
              ]}
            >
              {_("I'm looking for something")}
            </span>
            <Select
              border
              red
              value={[selectedCategory, selectedCategory]}
              onChange={value => handleChange(value)}
              css={[tw`text-xl font-semibold w-full`, tw`lg:(text-2xl)`]}
            >
              {categories &&
                categories?.map((c, index) => (
                  <Option
                    css={[tw`!pl-5`]}
                    value={c?.toString()}
                    label={c?.toString()}
                    selected={false}
                    analytics-id={`style dropdown:style:${index + 1}`}
                  />
                ))}
            </Select>
          </div>
        </div>

        {/* CTAs */}
        {cta && (
          <div
            css={[
              tw`col-span-2 col-start-1 flex mt-4 justify-center row-start-6 items-center gap-2`,
              tw`lg:(col-span-1 row-start-3 row-end-4 justify-start items-start my-0)`,
            ]}
          >
            {cta?.map((cta: CTA) => {
              return (
                <ButtonLink
                  {...{ [cta?.buttonType]: true }}
                  to={getCTALinkValue(cta)}
                  target={cta?.linkType === "external" ? "_blank" : "_self"}
                  css={[tw`w-full col-span-1`, tw`lg:w-auto`]}
                  key={cta?.title}
                >
                  {cta?.title}
                </ButtonLink>
              )
            })}
          </div>
        )}
      </section>
    </section>
  )
}

export default LifestyleHero
