import React, { useContext } from "react"
import { StillLookingProps } from "./Lifestyle.d"
import tw from "twin.macro"
import { Image } from "../../atoms/Image"
import { Button } from "../../atoms/Button"
import Icon from "../../atoms/Icon"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { LanguageContext } from "../../../contexts/Language"

/**
 *
 * @author Cody
 * @summary
 *
 *
 */

const StillLooking: React.FC<StillLookingProps> = ({
  series,
  backgroundImage,
  selectedSeriesIndex,
  onChange,
}) => {
  const { _ } = useContext(LanguageContext)
  // Tealium
  const { trackTealEvent } = useTealiumEvent()

  const handleTealiumEvent = (selection: string) => {
    trackTealEvent({
      tealium_event: "carousel_click",
      carousel_action: selection,
    })
  }
  React.useEffect(() => {
    const element = document.getElementById(
      `category-${selectedSeriesIndex + 1}`
    )

    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
      })
    }
  }, [selectedSeriesIndex])

  return (
    <section aria-label="Still Looking Section" css={[tw`bg-black pt-40`]}>
      <div
        css={[
          tw`relative flex h-[340px] overflow-hidden`,
          tw`md:(relative flex h-[420px])`,
        ]}
      >
        {backgroundImage && (
          <Image
            imageData={backgroundImage?.image}
            css={[
              tw`absolute top-0 bottom-0 w-full contrast-150`,
              "z-index: 0;",
            ]}
          />
        )}
      </div>
      <div
        css={[
          tw`relative -mt-64 grid grid-cols-2 grid-rows-2 w-auto mx-8 pb-10`,
          tw`sm:(-mt-80)`,
          tw`md:(relative -mt-28 flex items-center justify-center mx-4 z-20)`,
          selectedSeriesIndex === 0 &&
            series.length === 1 &&
            tw`justify-center grid-rows-1  grid-cols-1`,
          selectedSeriesIndex > 0 && tw`-mt-40 grid-cols-2 grid-rows-2`,
          selectedSeriesIndex === 0 &&
            series.length > 1 &&
            tw`-mt-52 grid-cols-1 grid-rows-1`,
        ]}
      >
        {selectedSeriesIndex > 0 && (
          <>
            <div
              css={[
                tw`text-center row-start-2 row-span-1 col-start-1 col-span-1`,
                tw`md:(text-center w-[550px])`,
                //series.length === 1 && tw`justify-center col-span-2`,
              ]}
              onClick={() => {
                onChange(
                  series[selectedSeriesIndex - 1],
                  selectedSeriesIndex - 1
                ),
                  handleTealiumEvent("left arrow")
              }}
              analytics-id="left car:vehicle carousel:vehicle"
            >
              <Image
                imageData={series[selectedSeriesIndex - 1].seriesImage.image}
                css={[
                  tw`min-h-[130px] h-auto inline-flex object-contain`,
                  tw`sm:(min-h-[200px])`,
                  tw`md:(min-h-[250px])`,
                  tw`lg:(min-h-[290px])`,
                ]}
                analytics-id="left car:vehicle carousel:vehicle"
              />
              <span
                css={[
                  tw`text-xs text-gray-300 uppercase tracking-widest font-light block mt-2`,
                  tw`md:(text-base)`,
                ]}
              >
                {series[selectedSeriesIndex - 1].seriesName}
              </span>
              <span
                css={[
                  tw`text-base text-white uppercase tracking-widest font-book block`,
                  tw`md:(text-2xl)`,
                ]}
              >
                {series[selectedSeriesIndex - 1].seriesCategory}
              </span>
            </div>
            <button
              css={[
                tw` bg-transparent absolute bottom-16 -left-7 transform -translate-y-1/2 z-20 hover:(bg-gray-800 bg-opacity-75) focus:(bg-gray-800 bg-opacity-75 outline-white) disabled:(opacity-0) rounded-none`,
                tw`md:(left-0 bottom-20)`,
                tw`lg:(left-0 -bottom-4)`,
              ]}
              onClick={() => {
                onChange(
                  series[selectedSeriesIndex - 1],
                  selectedSeriesIndex - 1
                )
                handleTealiumEvent("left arrow")
              }}
              analytics-id="left arrow:vehicle carousel:vehicle"
            >
              <Icon.Chevron
                css={[tw`m-2 h-8 inline lg:(m-4 h-16)`]}
                color="white"
                direction="left"
              />
            </button>
          </>
        )}
        <div
          css={[
            tw`row-start-1 col-span-2 flex items-end`,
            tw`md:(w-[350px] h-[300px] text-center flex items-center)`,
            tw`lg:(items-end)`,
          ]}
        >
          <h3
            css={[
              tw`font-light text-3xl mb-4 items-baseline text-center tracking-widest text-gray-300`,
              tw`md:(font-light mb-0 text-2xl items-baseline tracking-widest text-gray-300)`,
              tw`lg:(text-3xl)`,
            ]}
          >
            {_("Still looking for your perfect Toyota match?")}
          </h3>
        </div>
        {selectedSeriesIndex < series.length - 1 && (
          <>
            <div
              css={[
                tw`text-center row-start-2 col-start-2 col-span-1`,
                tw`md:(text-center w-[550px])`,
                series.length === 1 && tw`justify-center `,
              ]}
              onClick={() => {
                onChange(
                  series[selectedSeriesIndex + 1],
                  selectedSeriesIndex + 1
                )
                handleTealiumEvent("right arrow")
              }}
              analytics-id="right car:vehicle carousel:vehicle"
            >
              <Image
                imageData={series[selectedSeriesIndex + 1].seriesImage.image}
                css={[
                  tw`h-auto inline-flex object-contain min-h-[130px]`,
                  tw`sm:(min-h-[200px])`,
                  tw`md:(min-h-[250px])`,
                  tw`lg:(min-h-[290px])`,
                ]}
                objectFit={"contain"}
              />
              <span
                css={[
                  tw`text-xs text-gray-300 uppercase tracking-widest font-light block mt-2`,
                  tw`md:(text-base)`,
                ]}
              >
                {series[selectedSeriesIndex + 1].seriesName}
              </span>
              <span
                css={[
                  tw`text-base text-white uppercase tracking-widest font-book block min-h-[50px]`,
                  tw`md:(text-2xl)`,
                ]}
              >
                {series[selectedSeriesIndex + 1].seriesCategory}
              </span>
            </div>
            <button
              css={[
                tw`bg-transparent absolute bottom-16 -right-7 transform -translate-y-1/2 z-20 hover:(bg-gray-800 bg-opacity-75) focus:(bg-gray-800 bg-opacity-75 outline-white) disabled:(opacity-0)`,
                tw`md:(right-0 bottom-20)`,
                tw`lg:(right-0 -bottom-4)`,
              ]}
              onClick={() => {
                onChange(
                  series[selectedSeriesIndex + 1],
                  selectedSeriesIndex + 1
                )
                handleTealiumEvent("right arrow")
              }}
              analytics-id="right arrow:vehicle carousel:vehicle"
            >
              <Icon.Chevron
                css={[tw`m-2 h-8 inline lg:(m-4 h-16)`]}
                color="white"
                direction="right"
              />
            </button>
          </>
        )}
      </div>
    </section>
  )
}

export default StillLooking
