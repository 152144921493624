import React, { useContext, useEffect, useRef, useState } from "react"
import { graphql, PageProps } from "gatsby"
import { Layout } from "../components/atoms/Layout"
import SeriesSection from "../components/organisms/Lifestyle/SeriesSection"
import LifestyleHero from "../components/organisms/Lifestyle/Hero"
import StillLooking from "../components/organisms/Lifestyle/StillLooking"
import useTealiumView from "../hooks/Tealium/useTealiumView"
import { SEO } from "../components/atoms/SEO"
import { DisclaimersContext } from "../contexts/Disclaimers"
import { setDisclaimers } from "../contexts/Disclaimers/actions"
import { useTealiumContext } from "../contexts/Tealium"

const LifestylePage: React.FC<PageProps> = ({ data, location }: any) => {
  const { page } = data
  const [state, dispatch] = useContext(DisclaimersContext)

  const [selectedSeries, setSelectedSeries] = useState(
    page.seriesSection.lifestyleSeries[0]
  )
  const [selectedSeriesIndex, setSelectedSeriesIndex] = useState(0)

  const categories = page.seriesSection.lifestyleSeries.map(
    (s: { seriesCategory: string }) => s.seriesCategory
  )
  const handleCategoryChange = (category: string) => {
    setSelectedSeries(
      page.seriesSection.lifestyleSeries.find(
        (s: { seriesCategory: string }) => s.seriesCategory === category
      )
    )
    // find the index of the selected series where the category matches
    setSelectedSeriesIndex(
      page.seriesSection.lifestyleSeries.findIndex(
        (s: { seriesCategory: string }) => s.seriesCategory === category
      )
    )
    const categoryElement = document.getElementById(
      `category-${selectedSeriesIndex + 1}`
    )
    if (categoryElement) {
      categoryElement.scrollIntoView({
        behavior: "smooth",
        block: "center",
      })
    }
  }
  const handleSeriesChange = (series: any, index: number) => {
    setSelectedSeries(series)
    setSelectedSeriesIndex(index)
  }

  const seriesSectionRef = useRef(null)

  //Tealium

  const { handleTealView } = useTealiumView()
  const { updateVehicleTealData } = useTealiumContext()

  useEffect(() => {
    handleTealView({
      page_name: "lifestyle",
      tealium_event: "lifestyle_lander",
      page_type: "lifestyle",
    })
    window.scrollTo(0, 0)
  }, [])

  /* Sets and updates value for "vehicleTealData.vehicle_model_truncated". This value is used in Tealium calls (e.g. Inventory button clicks) */
  useEffect(() => {
    updateVehicleTealData({
      vehicle_model_truncated: `${selectedSeries?.seriesName}`,
    })
  }, [selectedSeries.seriesName])

  return (
    <Layout>
      <SEO
        url={location.href}
        title={page.title}
        keywords={page.seo?.keywords}
        schema={page.seo?.schema}
        description={page.seo?.description}
        canonicalPath={page.seo?.canonicalPath}
      />
      <LifestyleHero
        {...page.heroSection}
        showGradient={true}
        textInverted={true}
        itemsTop={true}
        categories={categories}
        onChange={(category: any) => handleCategoryChange(category)}
        seriesSectionRef={seriesSectionRef}
      />
      <SeriesSection
        {...page.seriesSection}
        selectedSeries={selectedSeries}
        setSelectedSeries={setSelectedSeries}
        selectedSeriesIndex={selectedSeriesIndex}
        setSelectedSeriesIndex={setSelectedSeriesIndex}
        seriesSectionRef={seriesSectionRef}
        ref={seriesSectionRef} // Make sure the ref is assigned correctly
      />
      <StillLooking
        series={page.seriesSection.lifestyleSeries}
        selectedSeriesIndex={selectedSeriesIndex}
        {...page.seriesSection}
        setSelectedSeries={setSelectedSeries}
        onChange={(series: any, index: number) => {
          handleSeriesChange(series, index)
          if (seriesSectionRef.current) {
            seriesSectionRef.current.scrollIntoView({
              behavior: "smooth",
              block: "center",
            })
          }
        }}
      />
    </Layout>
  )
}

export const query = graphql`
  query LifestylePageQuery($id: String) {
    page: sanityPageLifestyle(id: { eq: $id }) {
      id
      language
      title
      seo {
        keywords
        schema
        description
      }
      heroSection {
        ...GenericHeroData
      }
      seriesSection {
        _type
        description
        headingText
        logo {
          ...SanityImageData
        }
        backgroundImage {
          ...SanityImageData
        }
        lifestyleSeries {
          description
          perfectFor
          seriesCategory
          seriesName
          tagline
          cta {
            title
            buttonType
            externalUrl
            internalLink {
              slug {
                current
              }
            }
            anchor
            linkType
          }
          gallery {
            ...GallerySection
          }
          seriesImage {
            ...SanityImageData
          }
          seriesBackgroundImage {
            ...SanityImageData
          }
          vehicle {
            id
            year
            series {
              name
              mpgCity
              mpgHighway
              msrp
              slug
            }
            models {
              ... on SanityVehicleModelGroup {
                models {
                  ...model
                }
              }
              ... on SanityVehicleModel {
                ...model
              }
            }
          }
        }
      }
      disclaimers {
        code
        disclaimer
      }
      seo {
        keywords
        schema
        description
        canonicalPath
      }
    }
  }
`

export default LifestylePage
